import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link, Linkbox } from 'hds-react';
import { withPrefix } from "gatsby";
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/linkbox/code",
  "title": "Linkbox - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const IconCrossCircle = makeShortcode("IconCrossCircle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "empty",
      "style": {
        "position": "relative"
      }
    }}>{`Empty`}<a parentName="h4" {...{
        "href": "#empty",
        "aria-label": "empty permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{ backgroundColor: 'var(--color-black-5)', padding: 'var(--spacing-s)' }}>
  <div style={{ maxWidth: '320px' }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi">
      <div style={{ height: '106px' }} />
    </Linkbox>
  </div>
  <div style={{ maxWidth: '320px', marginTop: 'var(--spacing-s)' }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" withBorder>
      <div style={{ height: '106px' }} />
    </Linkbox>
  </div>
  <div style={{ maxWidth: '320px', marginTop: 'var(--spacing-s)' }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" noBackground>
      <div style={{ height: '106px' }} />
    </Linkbox>
  </div>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "with-heading-and-body-text",
      "style": {
        "position": "relative"
      }
    }}>{`With heading and body text`}<a parentName="h3" {...{
        "href": "#with-heading-and-body-text",
        "aria-label": "with heading and body text permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{ backgroundColor: 'var(--color-black-5)', padding: 'var(--spacing-s)' }}>
  <div style={{ maxWidth: '320px' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi"
      heading="Linkbox title"
      text="Linkbox text"
    />
  </div>
  <div style={{ maxWidth: '320px', marginTop: 'var(--spacing-s)' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi"
      heading="Linkbox title"
      text="Linkbox text"
      withBorder
    />
  </div>
  <div style={{ maxWidth: '320px', marginTop: 'var(--spacing-s)' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi"
      heading="Linkbox title"
      text="Linkbox text"
      noBackground
    />
  </div>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "internal-and-external-linkboxes",
      "style": {
        "position": "relative"
      }
    }}>{`Internal and external Linkboxes`}<a parentName="h3" {...{
        "href": "#internal-and-external-linkboxes",
        "aria-label": "internal and external linkboxes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{ backgroundColor: 'var(--color-black-5)', padding: 'var(--spacing-s)' }}>
  <div style={{ maxWidth: '320px' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi" 
      heading="Internal link" 
      text="This link points to a page belonging to the same website as the current page."
      withBorder
    />
  </div>
  <div style={{ maxWidth: '320px', marginTop: 'var(--spacing-s)' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi"
      heading="External link" 
      text="This link points to another page."
      openInExternalDomainAriaLabel="Opens a different website."
      external
      withBorder
    />
  </div>
  <div style={{ maxWidth: '320px', marginTop: 'var(--spacing-s)' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi"
      heading="External link" 
      openInNewTab
      openInNewTabAriaLabel="Opens in a new tab."
      text="This link points to another page and opens in a new tab."
      openInExternalDomainAriaLabel="Opens a different website."
      external
      withBorder
    />
  </div>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "with-image",
      "style": {
        "position": "relative"
      }
    }}>{`With image`}<a parentName="h3" {...{
        "href": "#with-image",
        "aria-label": "with image permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{ backgroundColor: 'var(--color-black-5)', padding: 'var(--spacing-s)' }}>
  <div style={{ maxWidth: '384px' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi"
      heading="Linkbox title"
      text="Linkbox text"
      imgProps={{ src: withPrefix("/images/foundation/visual-assets/placeholders/image-m@3x.png"), width: 384, height: 245 }}
    />
  </div>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "linkbox-size-variants",
      "style": {
        "position": "relative"
      }
    }}>{`Linkbox size variants`}<a parentName="h3" {...{
        "href": "#linkbox-size-variants",
        "aria-label": "linkbox size variants permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{ backgroundColor: 'var(--color-black-5)', padding: 'var(--spacing-s)' }}>
  <div style={{ maxWidth: '284px' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi"
      heading="Linkbox title"
      text="Linkbox text"
      size="small"
      imgProps={{ src: withPrefix('/images/foundation/visual-assets/placeholders/image-m@3x.png'), width: 284, height: 181 }}
    />
  </div>
  <div style={{ maxWidth: '384px', marginTop: 'var(--spacing-s)' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi"
      heading="Linkbox title"
      text="Linkbox text"
      size="medium"
      imgProps={{ src: withPrefix('/images/foundation/visual-assets/placeholders/image-m@3x.png'), width: 384, height: 245 }}
    />
  </div>
  <div style={{ maxWidth: '567px', marginTop: 'var(--spacing-s)' }}>
    <Linkbox
      linkboxAriaLabel="Linkbox: Helsinki Design System"
      linkAriaLabel="HDS"
      href="https://hds.hel.fi"
      heading="Linkbox title"
      text="Linkbox text"
      size="large"
      imgProps={{ src: withPrefix('/images/foundation/visual-assets/placeholders/image-m@3x.png'), width: 567, height: 363 }}
    />
  </div>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-linkbox-empty-for-custom-content--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/link" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCrossCircle mdxType="IconCrossCircle" />{` No `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-linkbox-empty-for-custom-content--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`heading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading of the Linkbox (uses the built-in heading)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`headingAriaLevel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls the aria-level of the built-in heading.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`external`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the link is marked as external (points outside of the domain).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`openInExternalDomainAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label which is read if the link is external.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`openInNewTab`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the link opens in a new tab.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`openInNewTabAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label which is read if the link is opened in a new tab.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls the size of the Linkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"small"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"medium"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"large"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`href`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hypertext reference of the link.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`linkAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label for the link (arrow or external icon) that is located at the bottom of the linkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`linkboxAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria label for the whole linkbox region. Remember to tell users of assistive technology that they are inside a linkbox. Check storybook examples on how it can be done.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`withBorder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, a border will be drawn around the card.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      